import DataSpotifyContext from "../context/dataSpotifyContext"
import Login from "./Login"
import Search from "./Search"
import Filters from "./Filters"
import Tracks from "./Tracks"
import Logo from "./Logo"
import Logout from "./Logout"
import { useState } from "react"

function App() {
	const [token, setToken] = useState("")
	const [categories, setCategories] = useState([])
	const [selectCategory, setSelectCategory] = useState("")
	const [playlists, setPlaylists] = useState([])
	const [selectPlaylist, setSelectPlaylist] = useState("")
	const [tracks, setTracks] = useState([])

	return (
		<DataSpotifyContext.Provider
			value={{
				token: token,
				getToken: token => {
					setToken(token)
				},
				categories: categories,
				getCategories: categories => {
					setCategories(categories)
				},
				selectCategory: selectCategory,
				handleSelectCategory: selectCategory => {
					setSelectCategory(selectCategory)
				},
				playlists: playlists,
				getPlaylists: playlists => {
					setPlaylists(playlists)
				},
				selectPlaylist: selectPlaylist,
				handleSelectPlaylist: selectPlaylist => {
					setSelectPlaylist(selectPlaylist)
				},
				tracks: tracks,
				getTracks: tracks => {
					setTracks(tracks)
				},
				logout: () => {
					setToken("")
					setCategories([])
					setSelectCategory("")
					setPlaylists([])
					setSelectPlaylist("")
					setTracks([])
				},
			}}
		>
			<main>
				{token ? (
					<div className='box-app'>
						<Logo />
						<Logout />
						<Search />
						<Filters />
						<Tracks />
					</div>
				) : (
					<div className='login-box'>
						<Logo />
						<Login />
					</div>
				)}
			</main>
		</DataSpotifyContext.Provider>
	)
}

export default App
