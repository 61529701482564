import React from "react"

const DataSpotifyContext = React.createContext({
	token: "",
	getToken: () => {},
	categories: [],
	getCategories: () => {},
	selectCategory: "",
	handleSelectCategory: () => {},
	playlists: [],
	getPlaylists: () => {},
	selectPlaylist: "",
	handleSelectPlaylist: () => {},
	tracks: [],
	getTracks: () => {},
	logout: () => {},
})

DataSpotifyContext.displayName = "DataSpotifyContext"

export default DataSpotifyContext
