import logo from "../image/Spotify_Logo.jpg"

function Logo() {
	return (
		<img
			className='logo'
			src={logo}
			alt='Logo Spotify. Zielony okręg z sylwetką trzech fal dźwiękowych w kształcie równoległych pasków'
		/>
	)
}

export default Logo
