import { useContext, useEffect, useState } from "react"
import axios from "axios"
import DataSpotifyContext from "../../context/dataSpotifyContext"

function Categories() {
	const dataSpotifyContext = useContext(DataSpotifyContext)
	const [selectedValue, setSelectedValue] = useState("default")

	useEffect(() => {
		const getCategories = async () => {
			try {
				const res = await axios.get("https://api.spotify.com/v1/browse/categories", {
					headers: {
						Authorization: `Bearer ${dataSpotifyContext.token}`,
					},
				})
				dataSpotifyContext.getCategories(res.data.categories.items)
			} catch (ex) {
				console.log("błąd: " + ex)
			}
		}
		getCategories()
	}, [dataSpotifyContext.token])

	useEffect(() => {
		if (!dataSpotifyContext.selectCategory) {
			setSelectedValue("")
		}
	}, [dataSpotifyContext.selectCategory])

	if (!dataSpotifyContext.categories) return null

	return (
		<select
			value={selectedValue}
			onChange={e => {
				setSelectedValue(e.target.value)
				dataSpotifyContext.handleSelectCategory(e.target.value)
			}}
		>
			<option className='option-default' value=''>
				-- Wybierz kategorię --
			</option>
			{dataSpotifyContext.categories.map(category => (
				<option key={category.id} value={category.id}>
					{category.name}
				</option>
			))}
		</select>
	)
}

export default Categories
