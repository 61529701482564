import { useContext, useEffect, useState } from "react"
import axios from "axios"
import DataSpotifyContext from "../../context/dataSpotifyContext"

function Playlists() {
	const dataSpotifyContext = useContext(DataSpotifyContext)
	const [selectedValue, setSelectedValue] = useState("default")

	useEffect(() => {
		const getPlaylists = async () => {
			try {
				const res = await axios.get(
					`https://api.spotify.com/v1/browse/categories/${dataSpotifyContext.selectCategory}/playlists`,
					{
						headers: {
							Authorization: `Bearer ${dataSpotifyContext.token}`,
						},
					}
				)
				dataSpotifyContext.getPlaylists(res.data.playlists.items)
			} catch (ex) {
				console.log("błąd: " + ex)
			}
		}
		if (dataSpotifyContext.selectCategory) {
			getPlaylists()
		} else {
			dataSpotifyContext.getPlaylists([])
			dataSpotifyContext.handleSelectPlaylist("")
		}
	}, [dataSpotifyContext.token, dataSpotifyContext.selectCategory])

	if (!dataSpotifyContext.selectCategory) return null

	return (
		<select
			value={selectedValue}
			onChange={e => {
				setSelectedValue(e.target.value)
				dataSpotifyContext.handleSelectPlaylist(e.target.value)
			}}
		>
			<option className='option-default' value=''>
				-- Wybierz Playliste --
			</option>
			{dataSpotifyContext.playlists.map((playlist, index) =>
				playlist !== null ? (
					<option key={playlist.id + index} value={playlist.id}>
						{playlist.name}
					</option>
				) : null
			)}
		</select>
	)
}

export default Playlists
