import { useContext } from "react"
import DataSpotifyContext from "../context/dataSpotifyContext"

function Logout() {
	const dataSpotifyContext = useContext(DataSpotifyContext)

	return (
		<button onClick={() => dataSpotifyContext.logout()} className='btn-login'>
			Wyloguj
		</button>
	)
}

export default Logout
