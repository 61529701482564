import { useContext } from "react"
import DataSpotifyContext from "../context/dataSpotifyContext"
import { spotifyConfig } from "../spotify"
import axios from "axios"

function Login() {
	const dataSpotifyContext = useContext(DataSpotifyContext)

	const loginHandle = async () => {
		try {
			axios("https://accounts.spotify.com/api/token", {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				data: `grant_type=client_credentials&client_id=${spotifyConfig.ClientID}&client_secret=${spotifyConfig.ClientSecret}`,
			}).then(res => {
				dataSpotifyContext.getToken(res.data.access_token)
			})
		} catch (ex) {
			console.log("błąd: " + ex)
		}
	}

	return (
		<button className='btn-login' onClick={loginHandle}>
			LOGIN
		</button>
	)
}

export default Login
