import { useContext, useState } from "react"
import DataSpotifyContext from "../context/dataSpotifyContext"
import axios from "axios"

function Search() {
	const dataSpotifyContext = useContext(DataSpotifyContext)
	const [searchTrack, setSearchTrack] = useState("")
	const [error, setError] = useState("")

	const handleSearchTrack = async () => {
		if (searchTrack) {
			setError("")
			dataSpotifyContext.getTracks([])
			dataSpotifyContext.handleSelectCategory("")
			dataSpotifyContext.handleSelectPlaylist("")
			try {
				const res = await axios.get(
					`https://api.spotify.com/v1/search?q=${searchTrack}&type=track`,
					{
						headers: {
							Authorization: `Bearer ${dataSpotifyContext.token}`,
						},
					}
				)
				const tracks = []
				for (const key in res.data.tracks.items) {
					tracks.push({ track: res.data.tracks.items[key] })
				}
				dataSpotifyContext.getTracks(tracks)
				setSearchTrack("")
			} catch (ex) {
				console.log("Błąd" + ex)
			}
		} else {
			setError("Musisz podać tytuł piosenki lub artystę!")
		}
	}

	return (
		<div style={{ width: "100%", textAlign: "center" }}>
			<input
				className='search'
				type='text'
				value={searchTrack}
				onKeyDown={e => e.key === "Enter" && handleSearchTrack()}
				onChange={e => setSearchTrack(e.target.value)}
				placeholder='Szukaj tytułu lub artyste...'
			/>
			<button onClick={handleSearchTrack} className='btn-search'>
				Szukaj
			</button>
			{error && <p className='error-info'>{error}</p>}
		</div>
	)
}

export default Search
