import Categories from "./Filters/Categories"
import Playlists from "./Filters/Playlists"

function Filters() {
	return (
		<>
			<div className='box-filter'>
				<Categories />
				<Playlists />
			</div>
		</>
	)
}

export default Filters
