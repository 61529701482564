import { useContext, useEffect, useState } from "react"
import DataSpotifyContext from "../context/dataSpotifyContext"
import axios from "axios"

function Tracks() {
	const dataSpotifyContext = useContext(DataSpotifyContext)
	const [currentAudio, setCurrentAudio] = useState(null)

	useEffect(() => {
		const getItemsSong = async () => {
			try {
				const res = await axios.get(
					`https://api.spotify.com/v1/playlists/${dataSpotifyContext.selectPlaylist}/tracks`,
					{
						headers: {
							Authorization: `Bearer ${dataSpotifyContext.token}`,
						},
					}
				)
				dataSpotifyContext.getTracks(res.data.items)
			} catch (ex) {
				console.log("błąd: " + ex)
			}
		}
		if (dataSpotifyContext.selectPlaylist) {
			getItemsSong()
		} else {
			dataSpotifyContext.getTracks([])
		}
	}, [dataSpotifyContext.selectPlaylist])

	const pauseTrack = e => {
		if (currentAudio && currentAudio !== e.target) {
			currentAudio.pause()
		}
		setCurrentAudio(e.target)
	}

	return (
		<section className='box-track'>
			{dataSpotifyContext.tracks.length ? (
				dataSpotifyContext.tracks.map(
					track =>
						track.track &&
						track.track.preview_url && (
							<div key={track.track.id} className='item-track'>
								<div className='head-item-track'>
									<img src={track.track.album.images[2].url} alt='' />
									<div>
										<p>
											<strong>{track.track.name}</strong>
										</p>
										<p>
											{track.track.artists.map((name, index) => (
												<span key={name.id}>
													{name.name}
													{index === track.track.artists.length - 1 ? "" : ", "}
												</span>
											))}
										</p>
										<a href={track.track.external_urls.spotify} target='_blank' rel='noopener'>
											Wysłuchaj w Spotify
										</a>
									</div>
								</div>
								<audio
									onPlay={e => pauseTrack(e)}
									src={track.track.preview_url}
									controls
								></audio>
							</div>
						)
				)
			) : (
				<p>Wybierz kategorię następnie playliste lub Wyszukaj tytułu lub autora</p>
			)}
		</section>
	)
}

export default Tracks
